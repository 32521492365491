<template>
  <!-- desking-area -->
  <div class="desking-area">
    <!-- accordion -->
    <div class="profile-accordion">
      <div class="accordion" id="accordionExample51">
        <div class="card">
          <div class="card-header" id="headingOne51">
            <h2 class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne51"
                aria-expanded="true"
                aria-controls="collapseOne51"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />Fees
                  </h3>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne51"
            class="collapse show"
            aria-labelledby="headingOne51"
            data-parent="#accordionExample51"
          >
            <div class="card-body">
              <div class="servicesinfo-box">
                <div class="sortable-items">
                  <form role="form" action="" method="POST">
                    <div class="multi-field-wrapper2 table-responsive-md">
                      <table class="table desksettig">
                        <thead>
                          <tr>
                            <th>Name of The Fee</th>
                            <th>Type</th>
                            <th>Tax</th>
                            <th>Amount $</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody class="multi-fields2 sortable ui-sortable">
                          <tr
                            v-for="(feedata, index) in feedata"
                            :key="feedata.id"
                            class="
                              ui-state-default
                              multi-field2
                              ui-sortable-handle
                            "
                          >
                            <td>
                              <div class="names">
                                <span class="ddd"
                                  ><img
                                    class="img-fluid"
                                    src="assets/img/dots.svg"
                                    alt=""
                                /></span>
                                <!-- @blur="editFee(feedata.id)" -->

                                <input
                                  type="text"
                                  @blur="editFee(feedata.id, index)"
                                  v-model="feedata.name"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </td>
                            <td>
                              <!--@change="editFee(feedata.id)"-->
                              <select
                                required
                                class="form-control"
                                v-model="feedata.type"
                                @change="editFee(feedata.id, index)"
                              >
                                <option value="Doc Fee">Doc Fee</option>
                                <option value="License Fee">License Fee</option>
                                <option value="Plate Fee">Plate Fee</option>
                                <option value="Prep Fee">Prep Fee</option>
                                <option value="Electronic  Filling Fee">
                                  Electronic Filling Fee
                                </option>
                              </select>
                            </td>
                            <td>
                              <span class="deal-type taxes-switch"
                                ><label label="" class="switch">
                                  <input
                                    type="checkbox"
                                    checked=""
                                    v-model="feedata.tax"
                                    @change="
                                      onChnageTaxSwitch(
                                        $event,
                                        feedata.id,
                                        index
                                      )
                                    "
                                  /><span class="slider round"></span>
                                </label>
                              </span>
                            </td>
                            <td>
                              <input
                                type="text"
                                @blur="editFee(feedata.id, index)"
                                v-model="feedata.ammount"
                                class="form-control"
                                placeholder=""
                              />
                            </td>

                            <td>
                              <img
                                @click="removeFees(feedata.id)"
                                class="remove-field2"
                                src="../../assets/img/closs.svg"
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr
                            v-for="(fee, counter) in fee"
                            :key="fee.id"
                            class="
                              ui-state-default
                              multi-field2
                              ui-sortable-handle
                            "
                          >
                            <td>
                              <div class="names">
                                <span class="ddd"
                                  ><img
                                    class="img-fluid"
                                    src="assets/img/dots.svg"
                                    alt=""
                                /></span>
                                <input
                                  type="text"
                                  v-model="fee.name"
                                  class="form-control"
                                  placeholder="Name"
                                />
                              </div>
                            </td>
                            <td>
                              <select class="form-control" v-model="fee.type">
                                <option value="Doc Fee">Doc Fee</option>
                                <option value="License Fee">License Fee</option>
                                <option value="Plate Fee">Plate Fee</option>
                                <option value="Prep Fee">Prep Fee</option>
                                <option value="Electronic  Filling Fee">
                                  Electronic Filling Fee
                                </option>
                              </select>
                            </td>
                            <td>
                              <span class="deal-type taxes-switch"
                                ><label label="" class="switch">
                                  <input
                                    type="checkbox"
                                    checked=""
                                    @change="onChnageTaxSwitch($event)"
                                  /><span class="slider round"></span>
                                </label>
                              </span>
                            </td>
                            <td>
                              <input
                                type="text"
                                @blur="addFee(counter)"
                                v-model="fee.customer_price"
                                class="form-control"
                                placeholder=""
                              />
                            </td>

                            <td>
                              <img
                                @click="deletefee(counter)"
                                class="remove-field2"
                                src="../../assets/img/closs.svg"
                                alt=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <a class="btn addmore" @click="addMoreFee">Add Item</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- accordion -->

    <!-- accordion -->
    <div class="profile-accordion">
      <div class="accordion" id="accordionExample57">
        <div class="card">
          <div class="card-header" id="headingOne57">
            <h2 class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne57"
                aria-expanded="true"
                aria-controls="collapseOne57"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />Taxes
                  </h3>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne57"
            class="collapse show"
            aria-labelledby="headingOne57"
            data-parent="#accordionExample57"
          >
            <div class="card-body">
              <div class="servicesinfo-box">
                <div class="sortable-items">
                  <div class="table-responsive-lg">
                    <table class="table desksettig table3">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Location</th>
                          <th>Sales Tax Rate</th>
                          <th>Taxable Maximum Rate</th>
                          <th>Over Maximum Rate %</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="tax in taxes" :key="tax.id">
                          <td>
                            <input
                              style="width: 190px"
                              type="text"
                              class="form-control"
                              v-model="tax.tax_name"
                            />
                          </td>
                          <td>
                            <select
                              class="form-control"
                              v-model="tax.tax_location"
                            >
                              <option value="CA">CA</option>
                              <option value="Los Angeles">Los Angeles</option>
                              <option value="Glendora">Glendora</option>
                            </select>
                          </td>
                          <td>
                            <vue-numeric
                              currency="%"
                              separator=","
                              class="form-control"
                              v-model="tax.sales_tax"
                            ></vue-numeric>
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              v-model="tax.maximum_rate"
                              placeholder=""
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              v-model="tax.over_max_rate"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Combined</td>
                          <td></td>
                          <td>%{{ calculateTax }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="option-table setting-oppp">
                  <!-- check -->
                  <div class="row">
                    <div class="col-md-10">
                      <label class="containerw">
                        <input type="checkbox" checked="" />
                        <span class="checkmark"></span>
                        Zero Finance Tax Rates In-State
                      </label>
                      <label class="containerw">
                        <input type="checkbox" checked="" />
                        <span class="checkmark"></span>
                        Zero Finance Tax Rates Out-of-State
                      </label>
                      <label class="containerw">
                        <input type="checkbox" checked="" />
                        <span class="checkmark"></span>
                        Tax At Destination when Customer is in State
                      </label>
                      <label class="containerw">
                        <input type="checkbox" checked="" />
                        <span class="checkmark"></span>
                        No City Tax/Unincorporated
                      </label>
                    </div>

                    <div class="col-md-2">
                      <div class="complate text-right stcom">
                        <ul>
                          <li>
                            <a class="updatetask" @click="updateTax">Update</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- check -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- accordion -->

    <!-- accordion -->
    <div class="profile-accordion">
      <div class="accordion" id="accordionExample52">
        <div class="card">
          <div class="card-header" id="headingOne52">
            <h2 class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne52"
                aria-expanded="true"
                aria-controls="collapseOne52"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />Add on
                  </h3>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne52"
            class="collapse show"
            aria-labelledby="headingOne52"
            data-parent="#accordionExample52"
          >
            <div class="card-body setticard">
              <!-- accordion -->
              <div class="profile-accordion">
                <div class="accordion" id="accordionExample53">
                  <div class="card">
                    <div class="card-header" id="headingOne53">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne53"
                          aria-expanded="true"
                          aria-controls="collapseOne53"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Vsc
                            </h3>
                          </div>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne53"
                      class="collapse show"
                      aria-labelledby="headingOne53"
                      data-parent="#accordionExample53"
                    >
                      <div class="card-body">
                        <div class="servicesinfo-box">
                          <div class="sortable-items">
                            <form role="form" action="" method="POST">
                              <div
                                class="multi-field-wrapper2 table-responsive-md"
                              >
                                <table class="table desksettig table2">
                                  <thead>
                                    <tr>
                                      <th>Company</th>
                                      <th>Cost $</th>
                                      <th>Customer Price $</th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody
                                    class="multi-fields2 sortable ui-sortable"
                                  >
                                    <tr
                                      v-for="(vscdata, index) in vscdata"
                                      :key="vscdata.id"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>

                                          <input
                                            v-model="vscdata.name"
                                            @blur="editVsc(vscdata.id, index)"
                                            type="text"
                                            placeholder="Name"
                                            class="form-control"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          v-model="vscdata.cost"
                                          @blur="editVsc(vscdata.id, index)"
                                          type="text"
                                          placeholder="Cost"
                                          class="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          v-model="vscdata.customer_price"
                                          @blur="editVsc(vscdata.id, index)"
                                          type="text"
                                          placeholder="Customer Price"
                                          class="form-control"
                                        />
                                      </td>

                                      <td>
                                        <img
                                          @click="removeVSC(vscdata.id)"
                                          class="remove-field2"
                                          src="../../assets/img/closs.svg"
                                          alt=""
                                        />
                                      </td>
                                    </tr>

                                    <!-- add more vsc -->
                                    <tr
                                      v-for="(vsc, counter) in vsc"
                                      :key="vsc.id"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>
                                          <input
                                            type="text"
                                            class="form-control"
                                            v-model="vsc.name"
                                            placeholder="Name"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Cost"
                                          v-model="vsc.cost"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          @blur="addVsc(counter)"
                                          v-model="vsc.customer_price"
                                          placeholder="Customer Price"
                                        />
                                      </td>

                                      <td>
                                        <img
                                          @click="deleteVsc(counter)"
                                          class="remove-field2"
                                          src="../../assets/img/closs.svg"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <a @click="addMoreVsc" class="btn addmore">
                                  Add Item
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accordion -->

              <!-- accordion -->
              <div class="profile-accordion">
                <div class="accordion" id="accordionExample54">
                  <div class="card">
                    <div class="card-header" id="headingOne54">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne54"
                          aria-expanded="true"
                          aria-controls="collapseOne54"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Maintenance
                            </h3>
                          </div>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne54"
                      class="collapse show"
                      aria-labelledby="headingOne54"
                      data-parent="#accordionExample54"
                    >
                      <div class="card-body">
                        <div class="servicesinfo-box">
                          <div class="sortable-items">
                            <form role="form" action="" method="POST">
                              <div
                                class="multi-field-wrapper2 table-responsive-md"
                              >
                                <table class="table desksettig table2">
                                  <thead>
                                    <tr>
                                      <th>Company</th>
                                      <th>Cost $</th>
                                      <th>Customer Price $</th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody
                                    class="multi-fields2 sortable ui-sortable"
                                  >
                                    <tr
                                      v-for="(
                                        maintenancedata, index
                                      ) in maintenancedata"
                                      :key="index"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>
                                          <input
                                            v-model="maintenancedata.name"
                                            type="text"
                                            @blur="
                                              editMaintenance(
                                                maintenancedata.id,
                                                index
                                              )
                                            "
                                            placeholder="Name"
                                            class="form-control"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          v-model="maintenancedata.cost"
                                          type="text"
                                          @blur="
                                            editMaintenance(
                                              maintenancedata.id,
                                              index
                                            )
                                          "
                                          placeholder="Cost"
                                          class="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          v-model="
                                            maintenancedata.customer_price
                                          "
                                          @blur="
                                            editMaintenance(
                                              maintenancedata.id,
                                              index
                                            )
                                          "
                                          type="text"
                                          placeholder="Customer Price"
                                          class="form-control"
                                        />
                                      </td>

                                      <td>
                                        <img
                                          @click="
                                            removeMaintaenence(
                                              maintenancedata.id
                                            )
                                          "
                                          class="remove-field2"
                                          src="../../assets/img/closs.svg"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                    <!-- add more-->
                                    <tr
                                      v-for="(
                                        maintenance, counter
                                      ) in maintenance"
                                      :key="maintenance.id"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>
                                          <input
                                            type="text"
                                            v-model="maintenance.name"
                                            class="form-control"
                                            placeholder="Company"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="maintenance.cost"
                                          placeholder="Cost"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          @blur="addMaintenance(counter)"
                                          v-model="maintenance.customer_price"
                                          placeholder="Customer Price"
                                        />
                                      </td>

                                      <td>
                                        <img
                                          @click="deleteMaintenance(counter)"
                                          class="remove-field2"
                                          src="../../assets/img/closs.svg"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <button
                                  @click="addMoreMaintenance"
                                  type="button"
                                  class="btn addmore"
                                >
                                  Add Item
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accordion -->

              <!-- accordion -->
              <div class="profile-accordion">
                <div class="accordion" id="accordionExample55">
                  <div class="card">
                    <div class="card-header" id="headingOne55">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne55"
                          aria-expanded="true"
                          aria-controls="collapseOne55"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Gap
                            </h3>
                          </div>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne55"
                      class="collapse show"
                      aria-labelledby="headingOne55"
                      data-parent="#accordionExample55"
                    >
                      <div class="card-body">
                        <div class="servicesinfo-box">
                          <div class="sortable-items">
                            <form role="form" action="" method="POST">
                              <div
                                class="multi-field-wrapper2 table-responsive-md"
                              >
                                <table class="table desksettig table2">
                                  <thead>
                                    <tr>
                                      <th>Company</th>
                                      <th>Cost $</th>
                                      <th>Customer Price $</th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody
                                    class="multi-fields2 sortable ui-sortable"
                                  >
                                    <tr
                                      v-for="(gapdata, index) in gapdata"
                                      :key="gapdata.id"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>

                                          <input
                                            v-model="gapdata.name"
                                            type="text"
                                            @blur="editGap(gapdata.id, index)"
                                            placeholder="Name"
                                            class="form-control"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          v-model="gapdata.cost"
                                          type="text"
                                          @blur="editGap(gapdata.id, index)"
                                          placeholder="Cost"
                                          class="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          v-model="gapdata.customer_price"
                                          @blur="editGap(gapdata.id, index)"
                                          type="text"
                                          placeholder="Customer Price"
                                          class="form-control"
                                        />
                                      </td>

                                      <td>
                                        <a
                                          @click="removeGap(gapdata.id)"
                                          class="btn apply"
                                          ><u>Delete</u></a
                                        >
                                      </td>
                                    </tr>

                                    <!-- add more gap-->
                                    <tr
                                      v-for="(gap, counter) in gap"
                                      :key="gap.id"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>
                                          <input
                                            type="text"
                                            v-model="gap.name"
                                            class="form-control"
                                            placeholder="Name"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="gap.cost"
                                          placeholder="Cost"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          @blur="addGap(counter)"
                                          v-model="gap.customer_price"
                                          placeholder="Customer Price"
                                        />
                                      </td>

                                      <td>
                                        <img
                                          @click="deleteGap(counter)"
                                          class="remove-field2"
                                          src="../../assets/img/closs.svg"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <button
                                  @click="addMoreGap"
                                  type="button"
                                  class="btn addmore"
                                >
                                  Add Item
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accordion -->

              <!-- accordion -->
              <div class="profile-accordion">
                <div class="accordion" id="accordionExample56">
                  <div class="card">
                    <div class="card-header" id="headingOne56">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne56"
                          aria-expanded=" true"
                          aria-controls="collapseOne56"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />After Market
                            </h3>
                          </div>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne56"
                      class="collapse show"
                      aria-labelledby="headingOne56"
                      data-parent="#accordionExample56"
                    >
                      <div class="card-body">
                        <div class="servicesinfo-box">
                          <div class="sortable-items">
                            <form role="form" action="" method="POST">
                              <div
                                class="multi-field-wrapper2 table-responsive-md"
                              >
                                <table class="table desksettig table2">
                                  <thead>
                                    <tr>
                                      <th>Company</th>
                                      <th>Cost $</th>
                                      <th>Customer Price $</th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody
                                    class="multi-fields2 sortable ui-sortable"
                                  >
                                    <tr
                                      v-for="(
                                        aftermarketdata, index
                                      ) in aftermarketdata"
                                      :key="aftermarketdata.id"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>

                                          <input
                                            v-model="aftermarketdata.name"
                                            type="text"
                                            placeholder="Name"
                                            @blur="
                                              editAfterMarket(
                                                aftermarketdata.id,
                                                index
                                              )
                                            "
                                            class="form-control"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          v-model="aftermarketdata.cost"
                                          type="text"
                                          placeholder="Cost"
                                          @blur="
                                            editAfterMarket(
                                              aftermarketdata.id,
                                              index
                                            )
                                          "
                                          class="form-control"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          v-model="
                                            aftermarketdata.customer_price
                                          "
                                          @blur="
                                            editAfterMarket(
                                              aftermarketdata.id,
                                              index
                                            )
                                          "
                                          type="text"
                                          placeholder="Customer Price"
                                          class="form-control"
                                        />
                                      </td>

                                      <td>
                                        <img
                                          @click="
                                            removeaftermarket(
                                              aftermarketdata.id
                                            )
                                          "
                                          class="remove-field2"
                                          src="../../assets/img/closs.svg"
                                          alt=""
                                        />
                                      </td>
                                    </tr>

                                    <!-- add more market place-->
                                    <tr
                                      v-for="(
                                        marketplace, counter
                                      ) in marketplace"
                                      :key="marketplace.id"
                                      class="
                                        ui-state-default
                                        multi-field2
                                        ui-sortable-handle
                                      "
                                    >
                                      <td>
                                        <div class="names">
                                          <span class="ddd"
                                            ><img
                                              class="img-fluid"
                                              src="assets/img/dots.svg"
                                              alt=""
                                          /></span>
                                          <input
                                            type="text"
                                            v-model="marketplace.name"
                                            class="form-control"
                                            placeholder="Company"
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="marketplace.cost"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          class="form-control"
                                          @blur="addMarketplace(counter)"
                                          v-model="marketplace.customer_price"
                                        />
                                      </td>

                                      <td>
                                        <img
                                          @click="deletemarketplace(counter)"
                                          class="remove-field2"
                                          src="../../assets/img/closs.svg"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <button
                                  @click="addMoreMarketplace"
                                  type="button"
                                  class="btn addmore"
                                >
                                  Add Item
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accordion -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-accordion">
      <div class="accordion" id="accordionExample52">
        <div class="card">
          <div class="card-header" id="headingOne52">
            <h2 class="mb-0">
              <button
                class="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne52"
                aria-expanded="true"
                aria-controls="collapseOne52"
              >
                <div class="detais-title">
                  <h3>
                    <img
                      class="img-fluid arrow-down"
                      src="../../assets/img/arrow-down.svg"
                      alt=""
                    />Lenders
                  </h3>
                </div>
              </button>
            </h2>
            <button
              style="position: relative; bottom: 28px"
              class="rem-btn pull-right"
              @click="openLenderAddModal"
            >
              Add
            </button>
          </div>

          <div
            id="collapseOne52"
            class="collapse show"
            aria-labelledby="headingOne52"
            data-parent="#accordionExample52"
          >
            <div class="card-body setticard">
              <div class="servicesinfo-box">
                <div class="sortable-items">
                  <div class="table-responsive-md">
                    <table class="table desksettig table3">
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>Open Liensource</th>
                          <th>Payment Type</th>
                          <th>Calculation Type</th>
                          <th>Excess Mileage Pre Charge</th>
                          <th>Excess Mileage Post Charge</th>
                          <th>Default Mileage</th>

                          <th>Security Deposit of Payments</th>
                          <th>Security Deposit Round Off $</th>
                          <th>Acquition Fee</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(lenderdata, index) in lenderdata"
                          :key="index"
                        >
                          <td>
                            <a @click="openLenderModal(index)" class="btn apply"
                              ><u>Open</u></a
                            >
                          </td>
                          <td>
                            <a class="btn apply" @click="editLenderModal(index)"
                              ><u>Edit</u></a
                            >
                          </td>
                          <td>
                            {{ lenderdata.source_name }}
                          </td>
                          <td>
                            {{ lenderdata.payment_type }}
                          </td>
                          <td>
                            {{ lenderdata.calculation_type }}
                          </td>

                          <td>
                            {{ lenderdata.milage_per_change }}
                          </td>
                          <td>
                            {{ lenderdata.milage_post_charge }}
                          </td>
                          <td>
                            {{ lenderdata.default_milage }}
                          </td>
                          <td>
                            {{ lenderdata.security_deposit }}
                          </td>
                          <td>
                            {{ lenderdata.deposity_roundoff }}
                          </td>
                          <td>
                            {{ lenderdata.acquition_fee }}
                          </td>
                        </tr>
                      </tbody>
                      <!-- <button
                        @click="addLender"
                        type="button"
                        class="add-field2"
                      >
                        Add Item
                      </button> -->
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add New Lender -->
    <div
      class="modal fade"
      id="openLenderAddModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Open Liensource</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="submitLender" autocomplete="off">
            <div class="modal-body">
              <div class="row add-member">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Lien Source Name</label>
                    <input
                      type="text"
                      v-model="lender.source_name"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Payment Type</label>
                    <input
                      type="text"
                      v-model="lender.payment_type"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Calculation Type</label>

                    <input
                      type="text"
                      v-model="lender.calculation_type"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Default Mileage</label>
                    <input
                      type="text"
                      v-model="lender.default_milage"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Excess Mileage Pre Charge</label>
                    <input
                      type="text"
                      v-model="lender.milage_per_change"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Excess Mileage Post Charge</label>
                    <input
                      type="text"
                      v-model="lender.milage_post_charge"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Security Deposit of Payments</label>
                    <input
                      type="text"
                      v-model="lender.security_deposit"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Security Deposit Round Off $</label>
                    <input
                      type="text"
                      v-model="lender.deposity_roundoff"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Acquition Fee</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="lender.acquition_fee"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p style="color: #3151a1; font-size: 14px; font-weight: 600">
                    Taxing Defaults
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="option-table setting-oppp">
                    <ul>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="1"
                            v-model="taxing" /><span
                            class="checkmark"
                          ></span></label
                        >Use Rebates to Cover Up Fronts when Needed
                      </li>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="2"
                            v-model="taxing" /><span
                            class="checkmark"
                          ></span></label
                        >Use Trade value to Cover Up Fronts when Needed
                      </li>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="3"
                            v-model="taxing"
                            checked="checked" /><span
                            class="checkmark"
                          ></span></label
                        >Include CCR Tax in Cap Cost
                      </li>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="4"
                            v-model="taxing" /><span
                            class="checkmark"
                          ></span></label
                        >Use Trade value to Cover Up Fronts when Needed
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../assets/img/22. Delete.svg" alt=""> Cancel</a> -->

              <button :disabled="btnLoader" type="submit" class="rem-btn">
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/23. Close.svg"
                    alt=""
                  />
                  Add</span
                >
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Add New Lender -->
    <!-- edit  Lender -->
    <div
      class="modal fade"
      id="editLenderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Liensource</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="editLender" autocomplete="off">
            <div class="modal-body">
              <div class="row add-member">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Lien Source Name</label>
                    <input
                      type="text"
                      v-model="editlenderdata.source_name"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Payment Type</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.payment_type"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Calculation Type</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.calculation_type"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Default Mileage</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.default_milage"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Excess Mileage Pre Charge</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.milage_per_change"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Excess Mileage Post Charge</label>
                    <input
                      type="text"
                      v-model="editlenderdata.milage_post_charge"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Security Deposit of Payments</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.security_deposit"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Security Deposit Round Off $</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.deposity_roundoff"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Acquition Fee</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.acquition_fee"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p style="color: #3151a1; font-size: 14px; font-weight: 600">
                    Taxing Defaults
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="option-table setting-oppp">
                    <ul>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="1"
                            id="1"
                            v-model="taxing" /><span
                            class="checkmark"
                          ></span></label
                        >Use Rebates to Cover Up Fronts when Needed
                      </li>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="2"
                            id="2"
                            v-model="taxing" /><span
                            class="checkmark"
                          ></span></label
                        >Use Trade value to Cover Up Fronts when Needed
                      </li>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="3"
                            id="3"
                            v-model="taxing"
                            checked="checked" /><span
                            class="checkmark"
                          ></span></label
                        >Include CCR Tax in Cap Cost
                      </li>
                      <li class="checkmargin">
                        <label class="containerw"
                          ><input
                            type="checkbox"
                            value="4"
                            id="4"
                            v-model="taxing" /><span
                            class="checkmark"
                          ></span></label
                        >Use Trade value to Cover Up Fronts when Needed
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../assets/img/22. Delete.svg" alt=""> Cancel</a> -->
              <a
                style="
                  color: #6d778e;
                  background: transparent;
                  border: 1px solid #a2a8b8;
                  border-radius: 100px;
                  width: 100px;
                "
                @click="deleteLender(editlenderdata.id)"
                class="btn btn-default"
                >Delete</a
              >
              <button :disabled="btnLoader" type="submit" class="rem-btn">
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"> Update</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit New Lender -->
    <!--open Lender-->
    <div
      class="modal fade"
      id="openLenderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Open Liensource</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="editLender" autocomplete="off">
            <div class="modal-body">
              <div class="row add-member">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Lien Source Name</label>
                    <input
                      type="text"
                      v-model="editlenderdata.source_name"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Payment Type</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.payment_type"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Calculation Type</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.calculation_type"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Default Mileage</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.default_milage"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Excess Mileage Pre Charge</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.milage_per_change"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Excess Mileage Post Charge</label>
                    <input
                      type="text"
                      v-model="editlenderdata.milage_post_charge"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Security Deposit of Payments</label>
                    <input
                      type="text"
                      v-model="editlenderdata.security_deposit"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Security Deposit Round Off $</label>
                    <input
                      type="text"
                      v-model="editlenderdata.deposity_roundoff"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Acquition Fee</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.acquition_fee"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Credit Tier Maximum</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.credit_tier_max"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Credit Tier Mimimum</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.credit_tier_min"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Advanced / Carry New %</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.credit_carry_new"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Credit Tier</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.credit_tier"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <p style="color: #3151a1; font-size: 14px; font-weight: 600">
                    Optional
                  </p>
                </div>
              </div>

              <div class="row add-member">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Year</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.year"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Make</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.make"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Modal</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.modal"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Trim</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="editlenderdata.trim"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Effective</label>
                    <date-range-picker
                      :min-date="yesterdayDate"
                      :locale-data="{ format: 'mmmm dd, yyyy' }"
                      v-model="ufDate"
                      :single-date-picker="true"
                      :show-dropdowns="true"
                      :auto-apply="true"
                      :ranges="false"
                    ></date-range-picker>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Expire</label>
                    <date-range-picker
                      :min-date="yesterdayDate"
                      :locale-data="{ format: 'mmmm dd, yyyy' }"
                      v-model="ufDate"
                      :single-date-picker="true"
                      :show-dropdowns="true"
                      :auto-apply="true"
                      :ranges="false"
                    ></date-range-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../assets/img/22. Delete.svg" alt=""> Cancel</a> -->

              <button :disabled="btnLoader" type="submit" class="rem-btn">
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"> Update</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- open lender-->
  </div>
  <!-- desking-box -->
</template>
<script>
import axios from "axios";
import $ from "jquery";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import VueNumeric from "vue-numeric";
export default {
  components: {
    DateRangePicker,
    VueNumeric,
  },
  data() {
    return {
      taxes: [],
      tax_country: 2,
      tax_city: 2,
      tax_state: 2,
      tax_tanssit: 1,
      ufDate: { startDate: new Date(), endDate: new Date() },
      yesterdayDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
      btnLoader: false,
      editlenderdata: {
        expire: new Date(),
        effective: new Date(),
      },
      fee: [],
      feedata: [],
      feesum: 0,
      vscdata: [],
      vscsum: 0,
      maintenancedata: [],
      gapdata: [],
      aftermarketdata: [],
      vsc: [],
      maintenance: [],
      gap: [],
      marketplace: [],
      summain: "",
      sumgap: "",
      aftermarhetsum: "",
      lenderdata: [],
      lender: {},
      taxing: [],
    };
  },
  created() {
    this.getFees();
    this.getVsc();
    this.getMaintenance();
    this.getGap();
    this.getaftermarket();
    this.getLender();
    this.getTexes();
  },
  mounted() {
    $("removefee").on("click", function () {
      // var id = $(this).find(".tskdetail").data("id");
      // if (id !== undefined && id != "undefined" && id != null && id != "") {
      //   // v.taskDetail(id);
      //   v.redirectIt(id)

      // }
      alert();
    });
  },
  computed: {
    calculateTax() {
      var tax = 0;
      this.taxes.forEach((data) => {
        tax = tax + data.sales_tax;
      });

      return tax;
    },
  },
  methods: {
    updateTax() {
      axios
        .post("update/tax", this.taxes, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getTexes();
            this.$toast.open({
              position: "top-right",
              message: "Taxes Updated",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTexes() {
      axios
        .get("get/Taxes", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.taxes = res.data.taxes;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openLenderModal(index) {
      this.taxing = [];
      this.editlenderdata = {};
      this.editlenderdata = this.lenderdata[index];
      if (this.lenderdata[index].taxing) {
        var arr = this.lenderdata[index].taxing.split(",");
        this.taxing = arr;
      }
      $("#openLenderModal").modal("show");
    },
    openLenderAddModal() {
      this.taxing = [];
      $("#openLenderAddModal").modal("show");
    },
    editAfterMarket(id, index) {
      axios
        .post(
          "update/aftermarket",
          { id: id, aftermarket: this.aftermarketdata[index] },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.getaftermarket();
            this.$toast.open({
              position: "top-right",
              message: "Aftermarket Updated",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editGap(id, index) {
      axios
        .post(
          "update/gap",
          { id: id, gap: this.gapdata[index] },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.getGap();
            this.$toast.open({
              position: "top-right",
              message: "Gap Updated",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editMaintenance(id, index) {
      axios
        .post(
          "update/maintenance",
          { id: id, maintenance: this.maintenancedata[index] },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.getMaintenance();
            this.$toast.open({
              position: "top-right",
              message: "Maintenance Updated",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editVsc(id, index) {
      axios
        .post(
          "update/vsc",
          { id: id, vsc: this.vscdata[index] },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.getVsc();
            this.$toast.open({
              position: "top-right",
              message: "Vsc Updated",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editFee(id, index) {
      axios
        .post(
          "update/fee",
          { id: id, fee: this.feedata[index] },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.getFees();
            this.$toast.open({
              position: "top-right",
              message: "Fees Updated",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeLender(counter) {
      this.lender.splice(counter, 1);
    },
    deleteLender(id) {
      axios
        .get("lender/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getLender();
            $("#editLenderModal").modal("hide");
            this.$toast.open({
              position: "top-right",
              message: "Lender Deleted Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addLender() {
      this.lender.push({
        term: "",
        sell_rate: "",
        buy_rate: "",
        payment: "",
      });
    },
    submitLender() {
      axios
        .post(
          "add/lender",
          { lender: this.lender, taxing: this.taxing },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.getLender();
            this.lender = {};
            $("#openLenderAddModal").modal("hide");
            this.$toast.open({
              position: "top-right",
              message: "Lender Added",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLender() {
      axios
        .get("get/lender", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.lenderdata = res.data.lender;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletemarketplace(counter) {
      this.marketplace.splice(counter, 1);
    },
    deletefee(counter) {
      this.fee.splice(counter, 1);
    },
    deleteVsc(counter) {
      this.vsc.splice(counter, 1);
    },
    deleteMaintenance(counter) {
      this.maintenance.splice(counter, 1);
    },
    deleteGap(counter) {
      this.gap.splice(counter, 1);
    },

    removeaftermarket(id) {
      axios
        .get("aftermarket/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getaftermarket();
            this.$toast.open({
              position: "top-right",
              message: "Aftermarket Deleted Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeMaintaenence(id) {
      axios
        .get("maintenance/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getMaintenance();
            this.$toast.open({
              position: "top-right",
              message: "Maintenance Deleted Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeFees(id) {
      axios
        .get("fees/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getFees();
            this.$toast.open({
              position: "top-right",
              message: "Fees Deleted Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeGap(id) {
      axios
        .get("gap/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getGap();
            this.$toast.open({
              position: "top-right",
              message: "Gap Deleted Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeVSC(id) {
      axios
        .get("Vsc/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getVsc();
            this.$toast.open({
              position: "top-right",
              message: "Vsc Deleted Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addMarketplace(counter) {
      if (this.marketplace[counter].cost == "") {
        this.$toast.open({
          position: "top-right",
          message: "Cost  field is empty",
          type: "info",
        });
        return false;
      }
      if (this.marketplace[counter].name == "") {
        this.$toast.open({
          position: "top-right",
          message: "Name  field is empty",
          type: "info",
        });
        return false;
      }
      if (this.marketplace[counter].customer_price == "") {
        this.$toast.open({
          position: "top-right",
          message: "Customer price  field is empty",
          type: "info",
        });
        return false;
      } else {
        axios
          .post("add/marketplace", this.marketplace[counter], {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.marketplace = [];
              this.getaftermarket();
              this.$toast.open({
                position: "top-right",
                message: "Aftermarket Added Added",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addMaintenance(counter) {
      if (this.maintenance[counter].name == "") {
        this.$toast.open({
          position: "top-right",
          message: "Company Field Required",
          type: "info",
        });
        return false;
      }
      if (this.maintenance[counter].cost == "") {
        this.$toast.open({
          position: "top-right",
          message: "Cost Field Required",
          type: "info",
        });
        return false;
      }
      if (this.maintenance[counter].customer_price == "") {
        this.$toast.open({
          position: "top-right",
          message: "Customer Price Field Required",
          type: "info",
        });
        return false;
      } else {
        axios
          .post("add/maintenance", this.maintenance[counter], {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.maintenance = [];
              this.getMaintenance();
              this.$toast.open({
                position: "top-right",
                message: "Maintenance Added",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addGap(counter) {
      if (this.gap[counter].name == "") {
        this.$toast.open({
          position: "top-right",
          message: "Company Field Required",
          type: "info",
        });
        return false;
      }
      if (this.gap[counter].cost == "") {
        this.$toast.open({
          position: "top-right",
          message: "Cost Field Required",
          type: "info",
        });
        return false;
      }
      if (this.gap[counter].customer_price == "") {
        this.$toast.open({
          position: "top-right",
          message: "Customer Price Field Required",
          type: "info",
        });
        return false;
      } else {
        axios
          .post("add/gap", this.gap[counter], {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.gap = [];
              this.getGap();
              this.$toast.open({
                position: "top-right",
                message: "Gap Added",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addVsc(counter) {
      if (this.vsc[counter].name == "") {
        this.$toast.open({
          position: "top-right",
          message: "Company Field Required",
          type: "info",
        });
        return false;
      }
      if (this.vsc[counter].cost == "") {
        this.$toast.open({
          position: "top-right",
          message: "Cost Field Required",
          type: "info",
        });
        return false;
      }
      if (this.vsc[counter].customer_price == "") {
        this.$toast.open({
          position: "top-right",
          message: "Customer Price Field Required",
          type: "info",
        });
        return false;
      } else {
        axios
          .post("add/vsc", this.vsc[counter], {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.vsc = [];
              this.getVsc();
              this.$toast.open({
                position: "top-right",
                message: "Vsc Added",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addFee(counter) {
      if (this.fee[counter].name == "") {
        this.$toast.open({
          position: "top-right",
          message: "Fee Name Field Required",
          type: "info",
        });
        return false;
      }

      if (this.fee[counter].type == "") {
        this.$toast.open({
          position: "top-right",
          message: "Type  Field Required",
          type: "info",
        });
        return false;
      }
      if (this.fee[counter].customer_price == "") {
        this.$toast.open({
          position: "top-right",
          message: "Customer Price  Field Required",
          type: "info",
        });
        return false;
      } else {
        axios
          .post("add/fee", this.fee[counter], {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            console.log({fee_response : res});
            if (res.data.success) {
              let newone = {
                id : res.data.id,
                ammount : this.fee[counter].customer_price,
                name : this.fee[counter].name,
                tax : this.fee[counter].tax,
                type : this.fee[counter].type,
              };
              this.fee.splice(counter,1);
              this.feedata.push(newone);
              // this.fee = [];
              // this.getFees();
              this.$toast.open({
                position: "top-right",
                message: "Fees Added",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addMoreFee() {
      this.fee.push({
        name: "",
        type: "",
        tax: 1,
        customer_price: "",
      });
    },
    addMoreVsc() {
      this.vsc.push({
        name: "",
        cost: "",
        customer_price: "",
      });
    },
    addMoreMaintenance() {
      this.maintenance.push({
        name: "",
        cost: "",
        customer_price: "",
      });
    },
    addMoreGap() {
      this.gap.push({
        name: "",
        cost: "",
        customer_price: "",
      });
    },
    addMoreMarketplace() {
      this.marketplace.push({
        name: "",
        cost: "",
        customer_price: "",
      });
    },
    onChnageTaxSwitch(event, id, index) {
      if (event.target.checked) {
        this.fee.tax = 1;
      } else {
        this.fee.tax = 0;
      }
      this.editFee(id, index);
    },
    getFees() {
      axios
        .get("get/fees/all", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.fees) {
              this.feedata = res.data.fees;
              this.feesum = res.data.sum;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editLenderModal(index) {
      this.taxing = [];
      this.editlenderdata = {};
      this.editlenderdata = this.lenderdata[index];
      if (this.lenderdata[index].taxing) {
        var arr = this.lenderdata[index].taxing.split(",");
        this.taxing = arr;
      }

      $("#editLenderModal").modal("show");
    },
    editLender() {
      axios
        .post(
          "update/lender",
          { data: this.editlenderdata, taxing: this.taxing },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            $("#editLenderModal").modal("hide");
            this.getLender();
            this.$toast.open({
              position: "top-right",
              message: "Lender Updated",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVsc() {
      axios
        .get("get/vsc/all", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.vsc) {
              this.vscdata = res.data.vsc;
              this.vscsum = res.data.sum;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMaintenance() {
      axios
        .get("get/maintenance/all", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.maintenance) {
              this.maintenancedata = res.data.maintenance;
              this.summain = res.data.summain;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGap() {
      axios
        .get("get/gap/all", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.gap) {
              this.gapdata = res.data.gap;
              this.sumgap = res.data.sumgap;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getaftermarket() {
      axios
        .get("get/aftermarket/all", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.aftermarket) {
              this.aftermarketdata = res.data.aftermarket;
              this.aftermarhetsum = res.data.aftermarhetsum;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.apply {
  width: 80px;
  display: block;
  background: #dbe5ff;
  border-radius: 3px;
  padding-left: 0;
  color: #3151a1!important;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
}
.checkmargin {
  margin-bottom: 10px;
}
.vue-daterange-picker {
  width: 100% !important;
}
.profile-accordion {
  margin-bottom: 60px;
}
.addmore {
  display: inline-block;
  background: #dbe5ff;
  border-radius: 30px;
  padding: 8px 15px;
  color: #3151a1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  text-decoration: none;
  margin: 5px;
}
.updatetask {
  cursor: pointer;
}
.desksettig.table .form-control {
  width: 190px;
}
.option-table.setting-oppp .containerw {
  
    margin-right: 45px;
}
</style>
